export default function createObjectForCsv(
  originalData,
  isActivityExport = false,
) {
  const newData = originalData.map((row) => {
    const newRow = JSON.parse(JSON.stringify(row));
    if (isActivityExport) {
      newRow.links = JSON.stringify(row.links);
      newRow.resourcePrefs = JSON.stringify(row.resourcePrefs);
    }
    return newRow;
  });
  return newData;
}
