import moment from 'moment';

export default function getFileName(prefix, infix, extension = 'csv', data) {
  let dateFrom;
  let dateTo;
  let minDate = null;
  let maxDate = null;
  let foundScheduledJobs = false;
  //eslint-disable-next-line no-unused-vars
  for (let row of data) {
    let rowDate;
    if (row.date) {
      foundScheduledJobs = true;
      rowDate = moment(row.date, 'YYYYMMDD');
    } else {
      continue;
    }
    if (!minDate && !maxDate) {
      minDate = rowDate;
      maxDate = rowDate;
    }
    if (minDate.isAfter(rowDate)) {
      minDate = rowDate;
    }
    if (maxDate.isBefore(rowDate)) {
      maxDate = rowDate;
    }
    dateFrom = minDate.format('YYMMDD');
    dateTo = maxDate.format('YYMMDD');
  }

  let fileName = '' + data.length + '-';
  if (prefix && infix) {
    fileName += prefix;
    fileName += '-';
    fileName += infix;
  } else if (prefix && !infix) {
    fileName += prefix;
  } else if (infix && !prefix) {
    fileName += infix;
  }
  if (!foundScheduledJobs) {
    fileName += '-' + moment().format('YYMMDD');
  } else {
    if (dateFrom) {
      fileName += '_';
      fileName += dateFrom;
    }
    if (dateTo) {
      fileName += '_';
      fileName += dateTo;
    }
  }
  fileName += '.';
  fileName += extension;
  return fileName;
}
