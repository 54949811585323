import Papa from 'papaparse';
import { DateTime } from 'luxon';
import moment from 'moment';
import createObjectForCsv from './createObjectForCsv';
import saveFile from 'save-as-file';
import getFileName from './getFileName';

export function exportCsv(
  prefix,
  infix,
  extension,
  columns,
  data,
  isActivityExport = false,
) {
  const fields = [];
  const dataForCsv = createObjectForCsv(data, isActivityExport);
  columns.forEach((heading) => {
    fields.push(heading.field || heading);
  });
  if (isActivityExport) {
    fields.push('resourcePrefs');
    fields.push('links');
  }
  const keys = Object.keys(fields);
  const dataObj = {
    fields,
    data: dataForCsv,
  };
  // To deal with situations where a value requires a leading zero to be preserved
  // we use Papaparse to wrap each field in quotes except for the links and resource preferences
  // fields as these are JSON within the csv.  This is achieved using a function in the quotes property
  // of the Papa config object
  const csv = Papa.unparse(dataObj, {
    quotes: (value, index) => {
      // links and resourcePrefs are the last 2 columns - need a better way to do this
      if (!isActivityExport) {
        return true;
      }
      if (index < keys.length - 2) {
        return true;
      }
      return false;
    },
    quoteChar: '"',
  });
  let filename;
  if (isActivityExport) {
    filename = getFileName(prefix, infix, extension, data);
  } else {
    const now = moment();

    filename = `${prefix}${infix ? '-' + infix : ''}-${now.format(
      'YYYY-MM-DD',
    )}.${extension}`;
  }
  const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
  saveFile(blob, filename);
}

export function exportJson(prefix, infix, data, prettify = false) {
  const now = DateTime.now();
  const filename = `${prefix}${infix ? '-' + infix : ''}-${now.toFormat(
    'yyyy-MM-dd',
  )}.json`;

  const outputData = prettify
    ? JSON.stringify(data, null, 2)
    : JSON.stringify(data);
  const blob = new Blob([outputData], {
    type: 'application/json;charset=utf-8',
  });
  saveFile(blob, filename);
}
